import React, { useState, useEffect } from 'react'
import Header from '../../../components/Yard/common/Header'
import './Inspection.css'
import CustomButton from '../../../components/Yard/Button/Button'
import { connect } from 'react-redux'
import DetailsBox from '../../../components/Yard/detailBox/DetailsBox'
import TextArea from '../../../components/Yard/textArea/TextArea'
import DateTime from '../../../components/Yard/dateTime/DateTime'
import Services from './comp/Services'
import API from '../../../api'
import { message, Spin, Button } from 'antd'
import PictureCard from '../../../components/upload/PictureCard'
import moment from 'moment'
import { navigate } from 'gatsby'
import { Routes } from '../../../utils/route'
import { IndustryType } from '../../../utils/helper'
import YardDetails from '../../../components/data-display/card/YardDetails'
import EquipmentDet from '../equipment/comp/EquipmentDet'
import EquipmentDoc from '../equipment/comp/EquipmentDoc'
import Head from '../../../components/Yard/header/Header'

const Inspection = props => {
  const { fetchServiceDetails, inspectFinish, imageDelete } = API

  const id = props.id
  const [state, setState] = useState(null)
  const [textArea, setTextArea] = useState('')
  const [oxygenPercentage, setOxygenPercentage] = useState(null)
  const [toxicityPPM, setToxicityPPM] = useState(null)
  const [lelPercentage, setLelPercentage] = useState(null)
  const [h2sPPM, setH2sPPM] = useState(null)
  const [image, setImage] = useState([])

  const [date, setDate] = useState(
    moment(new Date()).format('YYYY-MM-DD'),
    toString()
  )
  const [time, setTime] = useState(moment().format('HH:mm'))
  const [services, setServices] = useState(null)
  const [cleanServiceState, setCleanServiceState] = useState(null)
  const [additionalServiceState, setAddtionalServiceState] = useState(null)
  // const [otherServiceState, setOtherServiceState] = useState(null);
  const [preWashImage, setPreWashImages] = useState([])
  const [loading, setLoading] = useState(true)
  const [preCheckedServices, setPreCheckedServices] = useState(null)
  const [selectedRadioOption, setSelectedRadioOptios] = useState(null)

  useEffect(() => {
    const callback = val => {
      if (val === true) {
        setLoading(false)
        if (props?.yardHandData) {
          filterEquipmentDetails()
        }
      }
    }
    Routes('yard', callback)
  }, [])

  useEffect(() => {
    if (preCheckedServices != null) {
      fetchServices()
    }
  }, [preCheckedServices])
  const onFinish = async type => {
    const ap = moment(`${date} ${time}`).format('HH:mm')
    const dMoment = moment(date).format('MM/DD/yyyy')
    const d = new Date(`${dMoment} ${ap}`)
    const s = []

    cleanServiceState &&
      cleanServiceState.map((val, index) => {
        if (val.status === true) {
          s.push({
            id: val.id,
            service_id: val.service_id,
            price: val.price,
            tax: val.tax,
            sub_service_id: val.sub_service_id
          })
        }
      })
    additionalServiceState &&
      additionalServiceState.map((val, index) => {
        if (val.status === true) {
          s.push({
            id: val.id,
            service_id: val.service_id,
            price: val.price,
            tax: val.tax,
            sub_service_id: val.sub_service_id
          })
        }
      })
    // otherServiceState && otherServiceState.map((val,index) =>{
    //     if(val.status === true){
    //         s.push({id:val.id,service_id:val.service_id,price:val.price,tax:val.tax,sub_service_id:val.sub_service_id})
    //     }
    // })

    if (IndustryType() !== 'Food_Grade' && s.length === 0 && type === 'begin') {
      message.error({
        content: `PLEASE SELECT ATLEAST ONE SERVICE TYPE TO BEGIN WASH`,
        duration: 2
      })
    } else {
      let data = {
        images: image,
        services: s,
        note: textArea,
        datetime: d.getTime().toString(),
        ticket_id: state.ticket_id,
        status: type == 'approval' ? 3210 : 3215,
        h2sPPM,
        toxicityPPM,
        oxygenPercentage,
        lelPercentage
      }
      if (IndustryType() === 'Food_Grade') {
        delete data.services
      }
      try {
        const res = await inspectFinish(data)
        if (type === 'approval' && res.data.success === true) {
          navigate('/yard/home')
        } else if (type === 'begin' && res.data.success === true) {
          localStorage.setItem('startTime', d.getTime().toString())

          if (IndustryType() === 'Food_Grade') {
            navigate(`/yard/selectservice/${state.ticket_ref_id}`, {
              state: { item: 'In Wash' }
            })
          } else {
            navigate(`/yard/wash_complete/${state.ticket_ref_id}`, {
              state: { item: d.getTime().toString() }
            })
          }
        }
        if (res.data.success === false) {
          message.error({
            content: `Not able to perform the action`,
            duration: 2
          })
        }
      } catch (e) {
        message.error({
          content: `Not able to perform the action`,
          duration: 2
        })
      }
    }
  }
  const filterEquipmentDetails = () => {
    const filteredEquipment = props?.yardHandData?.filter((val, index) => {
      if (val.ticket_ref_id == id) {
        return val
      }
    })
    if (filteredEquipment && filteredEquipment.length > 0) {
      setState(...filteredEquipment)
      setTextArea(
        filteredEquipment[0]?.remark ? filteredEquipment[0].remark : ''
      )
      const serve = []

      filteredEquipment[0].services.map((val, index) => {
        serve.push(val.id)
      })

      setPreCheckedServices(serve)

      const imageArr = []
      const item =
        filteredEquipment[0].asset_txns_details?.asset_washout_docs ?? []
      item.map((val, index) => {
        if (val?.doc_name === 'Pre Wash Images') {
          imageArr.push({
            doc_id: val.doc_id,
            uid: index,
            name: val.doc_name,
            status: 'done',
            url: val.doc_path,
            thumbUrl: val.doc_path
          })
        }
      })
      setPreWashImages(imageArr ?? [])
    }
  }

  const fetchServices = async () => {
    const result = await fetchServiceDetails(
      state?.asset_details?.equipment_type_details?.param_value
    )
    // const otherService = []
    const additionalService = []
    const cleaningService = []
    if (result.data.success === true) {
      result.data.data.filter((val, index) => {
        if (val['service_group_name.param_description'] == 'Cleaning Service') {
          cleaningService.push(val)
        }
        if (
          val['service_group_name.param_description'] == 'Additional Service'
        ) {
          additionalService.push(val)
        }
        // if(val['service_group_name.param_description'] == 'Other Service'){
        //     otherService.push(val)
        // }
      })
      const cleanService =
        cleaningService && cleaningService?.length > 0 ? cleaningService : null
      const addtionalServe =
        additionalService && additionalService?.length > 0
          ? additionalService
          : null
      setServices({
        CleaningService: cleanService,
        AdditionalService: addtionalServe
      })

      const radioSelected = {}
      const clean = cleaningService?.map((val, index) => {
        if (preCheckedServices?.includes(val.id)) {
          radioSelected[val['service_name.param_description']] = val.id
          return {
            id: val?.id,
            price: val.price,
            service_id: val.service_id,
            name: val['sub_service_name.param_description'],
            status: true,
            tax: val.tax,
            sub_service_id: val.sub_service_id
          }
        } else {
          return {
            id: val.id,
            price: val.price,
            service_id: val.service_id,
            name: val['sub_service_name.param_description'],
            status: false,
            tax: val.tax,
            sub_service_id: val.sub_service_id
          }
        }
      })
      setCleanServiceState(
        cleaningService && cleaningService?.length > 0 ? clean : null
      )
      const additional = additionalService?.map((val, index) => {
        if (preCheckedServices?.includes(val.id)) {
          radioSelected[val['service_name.param_description']] = val.id
          return {
            id: val.id,
            price: val.price,
            service_id: val.service_id,
            name: val['sub_service_name.param_description'],
            status: true,
            tax: val.tax,
            sub_service_id: val.sub_service_id
          }
        } else {
          return {
            id: val.id,
            price: val.price,
            service_id: val.service_id,
            name: val['sub_service_name.param_description'],
            status: false,
            tax: val.tax,
            sub_service_id: val.sub_service_id
          }
        }
      })

      setSelectedRadioOptios(radioSelected)
      setAddtionalServiceState(
        additionalService && additionalService?.length > 0 ? additional : null
      )
    }
    if (result.data.success === false) {
      setServices({ failed: true })
      message.error({ content: `failed to fetch services`, duration: 2 })
    }
  }
  const handleServiceState = (state, ind, type) => {
    if (type == 'clean') {
      const filterVal = cleanServiceState.filter(val => val.id === ind)

      const key = cleanServiceState?.map((val, index) => {
        if (filterVal[0]?.service_id === val.service_id) {
          if (val.id == ind) {
            val.status = true
            return val
          } else {
            val.status = false
            return val
          }
        } else {
          return val
        }
      })

      setCleanServiceState(key)
    }
    if (type == 'additional') {
      const filterVal = additionalServiceState?.filter(val => val.id === ind)
      const key = additionalServiceState?.map((val, index) => {
        if (filterVal[0]?.service_id === val.service_id) {
          if (val.id == ind) {
            val.status = true
            return val
          } else {
            val.status = false
            return val
          }
        } else {
          return val
        }
      })
      setAddtionalServiceState(key)
    }
    // if(type == 'other'){
    //     const key = otherServiceState?.map((val,index) =>{
    //         if(val.sub_service_id == ind){
    //             val.status = state
    //             return val
    //         }else{
    //             return val
    //         }
    //     })
    //     setOtherServiceState(key)
    // }
  }
  const removeImage = (img, url) => {
    const imgArr = [...preWashImage]
    imgArr.map((_img, i) => {
      if (_img.doc_id === img) {
        imageDelete(img).then(res => {
          imgArr.splice(i)
        })
      }
    })
    setPreWashImages(imgArr)
  }
  if (!loading && services) {
    return (
      <>
        <Head />
        <section className='inspection'>
          <Header
            headName='Inspection Details'
            LinkName={`/yard/equipment/${id}`}
          />
          <div className='inspection_container'>
            {IndustryType() !== 'Food_Grade' ? (
              <EquipmentDet data={state} />
            ) : (
              <>
                <EquipmentDet data={state} />
                <EquipmentDoc
                  data={state.asset_txns_details.asset_washout_docs}
                />
                <div style={{ marginLeft: 15 }}>
                  <h2 style={{ fontSize: 18, fontWeight: '500', marginTop: 8 }}>
                    Oxygen %
                  </h2>
                  <input
                    value={oxygenPercentage}
                    onChange={e => {
                      setOxygenPercentage(e.target.value)
                    }}
                    style={{
                      borderRadius: 4,
                      borderWidth: 1,

                      padding: 4,
                      height: 24
                    }}
                    type='text'
                  />
                </div>
                <div style={{ marginLeft: 15 }}>
                  <h2 style={{ fontSize: 18, fontWeight: '500', marginTop: 8 }}>
                    H2S PPM
                  </h2>
                  <input
                    value={h2sPPM}
                    onChange={e => {
                      setH2sPPM(e.target.value)
                    }}
                    style={{
                      borderRadius: 4,
                      borderWidth: 1,

                      padding: 4,
                      height: 24
                    }}
                    type='text'
                  />
                </div>
                <div style={{ marginLeft: 15 }}>
                  <h2 style={{ fontSize: 18, fontWeight: '500', marginTop: 8 }}>
                    Toxicity PPM
                  </h2>
                  <input
                    value={toxicityPPM}
                    onChange={e => {
                      setToxicityPPM(e.target.value)
                    }}
                    style={{
                      borderRadius: 4,
                      borderWidth: 1,

                      padding: 4,
                      height: 24
                    }}
                    type='text'
                  />
                </div>
                <div style={{ marginLeft: 15 }}>
                  <h2 style={{ fontSize: 18, fontWeight: '500', marginTop: 8 }}>
                    LEL %
                  </h2>
                  <input
                    value={lelPercentage}
                    onChange={e => {
                      setLelPercentage(e.target.value)
                    }}
                    style={{
                      borderRadius: 4,
                      borderWidth: 1,
                      padding: 4,
                      height: 24
                    }}
                    type='text'
                  />
                </div>
                <TextArea head='Note' value={textArea} change={setTextArea} />
                <div className='wash_combo_upload_lt'>pre wash images</div>
                <PictureCard
                  resp='resp'
                  style={{ marginTop: 7, marginLeft: 17 }}
                  hide={true}
                  response={e => setImage(e)}
                  data={preWashImage}
                  removeImage={removeImage}
                />

                <DateTime
                  label_1='date'
                  label_2='time'
                  date={date}
                  time={time}
                  setTime={setTime}
                  setDate={setDate}
                />
                <div className='inspection_begin_content'>
                  <CustomButton
                    className='inspection_begin_1'
                    name='Need Approval?'
                    onClick={() => onFinish('approval')}
                  />

                  <CustomButton
                    className='inspection_begin_2'
                    name='begin wash'
                    onClick={() => onFinish('begin')}
                  />
                </div>
              </>
              // <YardDetails
              //   button={
              //     <div>
              //       <Button
              //         style={{ marginRight: 20 }}
              //         onClick={() => onFinish('approval')}
              //       >
              //         NEED APPROVAL?
              //       </Button>
              //       <Button
              //         onClick={() => onFinish('begin')}
              //         style={{ color: 'white', background: 'grey' }}
              //       >
              //         BEGIN WASH
              //       </Button>
              //     </div>
              //   }
              //   data={state}
              //   id={id}
              //   pictureCard={
              //     <PictureCard
              //       resp='resp'
              //       style={{ marginTop: 20 }}
              //       hide={true}
              //       response={e => setImage(e)}
              //       data={preWashImage}
              //       removeImage={removeImage}
              //     />
              //   }
              // >
              //   <div>
              //     {' '}
              //     <DateTime
              //       label_1='date'
              //       label_2='time'
              //       date={date}
              //       time={time}
              //       setTime={setTime}
              //       setDate={setDate}
              //     />
              //   </div>
              // </YardDetails>
            )}
            {IndustryType() !== 'Food_Grade' && (
              <>
                <div className='wash_combo_upload_lt'>pre wash images</div>
                <PictureCard
                  resp='resp'
                  style={{ marginTop: 7, marginLeft: 17 }}
                  hide={true}
                  response={e => setImage(e)}
                  data={preWashImage}
                  removeImage={removeImage}
                />
                {/* <InspectionImg /> */}
                {state?.status_remark && (
                  <>
                    <div style={{ marginTop: 20 }}>
                      <h3 style={{ marginLeft: 10 }}>Manager Approval Notes</h3>
                    </div>
                    <div style={{ marginLeft: 10 }}>
                      <p
                        style={{
                          fontWeight: 'normal',
                          fontWeight: '300',
                          fontSize: 18
                        }}
                      >
                        {state?.status_remark}
                      </p>
                    </div>
                  </>
                )}
                <div style={{ marginLeft: 15 }}>
                  <h2 style={{ fontSize: 18, fontWeight: '500', marginTop: 8 }}>
                    Oxygen %
                  </h2>
                  <input
                    value={oxygenPercentage}
                    onChange={e => {
                      setOxygenPercentage(e.target.value)
                    }}
                    style={{
                      borderRadius: 4,
                      borderWidth: 1,

                      padding: 4,
                      height: 24
                    }}
                    type='text'
                  />
                </div>
                <div style={{ marginLeft: 15 }}>
                  <h2 style={{ fontSize: 18, fontWeight: '500', marginTop: 8 }}>
                    H2S PPM
                  </h2>
                  <input
                    value={h2sPPM}
                    onChange={e => {
                      setH2sPPM(e.target.value)
                    }}
                    style={{
                      borderRadius: 4,
                      borderWidth: 1,

                      padding: 4,
                      height: 24
                    }}
                    type='text'
                  />
                </div>
                <div style={{ marginLeft: 15 }}>
                  <h2 style={{ fontSize: 18, fontWeight: '500', marginTop: 8 }}>
                    Toxicity PPM
                  </h2>
                  <input
                    value={toxicityPPM}
                    onChange={e => {
                      setToxicityPPM(e.target.value)
                    }}
                    style={{
                      borderRadius: 4,
                      borderWidth: 1,

                      padding: 4,
                      height: 24
                    }}
                    type='text'
                  />
                </div>
                <div style={{ marginLeft: 15 }}>
                  <h2 style={{ fontSize: 18, fontWeight: '500', marginTop: 8 }}>
                    LEL %
                  </h2>
                  <input
                    value={lelPercentage}
                    onChange={e => {
                      setLelPercentage(e.target.value)
                    }}
                    style={{
                      borderRadius: 4,
                      borderWidth: 1,

                      padding: 4,
                      height: 24
                    }}
                    type='text'
                  />
                </div>
                <TextArea head='Note' value={textArea} change={setTextArea} />
                {services &&
                selectedRadioOption &&
                services?.failed === undefined ? (
                  <Services
                    data={services}
                    selectedRadioOption={selectedRadioOption}
                    cleanServiceState={cleanServiceState}
                    additionalServiceState={additionalServiceState}
                    preCheckedServices={preCheckedServices}
                    checkHandler={(state, index, type) =>
                      handleServiceState(state, index, type)
                    }
                  />
                ) : services && services?.failed === true ? (
                  <span className='text-center'>
                    FAILED TO FETCH SERVICES !!
                  </span>
                ) : (
                  <span className='text-center'>FETCHING SERVICES...</span>
                )}
                <DateTime
                  label_1='date'
                  label_2='time'
                  date={date}
                  time={time}
                  setTime={setTime}
                  setDate={setDate}
                />
                <div className='inspection_begin_content'>
                  <CustomButton
                    className='inspection_begin_1'
                    name='Need Approval?'
                    onClick={() => onFinish('approval')}
                  />

                  <CustomButton
                    className='inspection_begin_2'
                    name='begin wash'
                    onClick={() => onFinish('begin')}
                  />
                </div>{' '}
              </>
            )}
          </div>
        </section>
      </>
    )
  } else {
    return (
      <div className='spinner-main'>
        <Spin />
      </div>
    )
  }
}
const mapStateToProps = state => ({
  yardHandData: state.yardHandData
})
export default connect(mapStateToProps, null)(Inspection)
